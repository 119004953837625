export const ADD_TO_FAQS = "ADD_TO_FAQS";
export const DELETE_FROM_FAQS = "DELETE_FROM_FAQS";
export const DELETE_ALL_FROM_FAQS = "DELETE_ALL_FROM_FAQS";

// add to faqs
export const addToFaqs = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Added To Faqs", {
        appearance: "success",
        autoDismiss: true
      });
    }
    dispatch({ type: ADD_TO_FAQS, payload: item });
  };
};

// delete from faqs
export const deleteFromFaqs = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Faqs", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_FROM_FAQS, payload: item });
  };
};

//delete all from faqs
export const deleteAllFromFaqs = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Faqs", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_FAQS });
  };
};
