import {
  ADD_TO_FAQS,
  DELETE_FROM_FAQS,
  DELETE_ALL_FROM_FAQS
} from "../actions/faqsActions";

const initState = [];

const faqsReducer = (state = initState, action) => {
  const faqsItems = state,
    product = action.payload;

  if (action.type === ADD_TO_FAQS) {
    const faqsItem = faqsItems.filter(
      item => item.id === product.id
    )[0];
    if (faqsItem === undefined) {
      return [...faqsItems, product];
    } else {
      return faqsItems;
    }
  }

  if (action.type === DELETE_FROM_FAQS) {
    const remainingItems = (faqsItems, product) =>
      faqsItems.filter(faqsItem => faqsItem.id !== product.id);
    return remainingItems(faqsItems, product);
  }

  if (action.type === DELETE_ALL_FROM_FAQS) {
    return faqsItems.filter(item => {
      return false;
    });
  }

  return faqsItems;
};

export default faqsReducer;
